<template>
	<CSidebar
		fixed
		:minimize="minimize"
		:show="show"
		@update:show="value => $store.commit('set', ['sidebarShow', value])"
	>
		<CSidebarBrand class="d-md-down-none sidebar-brand" to="/">
			<CIcon
				class="c-sidebar-brand-full"
				:src="logo"
				size="custom-size"
				:height="40"
				view-box="0 0 556 134"
			/>
			<CIcon
				class="c-sidebar-brand-minimized"
				:src="logoSmall"
				size="custom-size"
				:height="40"
				view-box="0 0 110 134"
			/>
		</CSidebarBrand>

		<CRenderFunction flat :content-to-render="computedSidebar" />
		<!-- <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    /> -->
	</CSidebar>
</template>

<script>
import i18n from "@/shared/plugins/i18n"
import { USER_ROLE } from "@/shared/plugins/constants"
import logo from "@/assets/img/carezone-white.png"
import logoSmall from "@/assets/img/carezone.png"
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("authentication")

const allItems = [
	// Admin Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.AdminManagement"),
		icon: "cil-user",
		to: "/admins",
		roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
	},

	// Appointment Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.AppointmentManagement"),
		icon: "cil-calendar",
		to: "/appointments",
		roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
	},

	// User Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.ManagerCustomer"),
		icon: "cil-user-follow",
		to: "/customers",
		roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
	},

	// Message Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.MessageManagement"),
		icon: "cil-speech",
		to: "/messages",
		roles: [USER_ROLE.SYSTEM_ADMIN],
	},

	// Clinic Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.ClinicManagement"),
		icon: "cil-shield-alt",
		to: "/clinics",
		roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],
	},
	// Topic Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.TopicManagement"),
		icon: "cil-comment-square",
		to: "/topics",
		roles: [USER_ROLE.SYSTEM_ADMIN],
	},
	// Banner Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.BannerManagement"),
		icon: "cil-file",
		to: "/banners",
		roles: [USER_ROLE.SYSTEM_ADMIN],
	},
	// Notification Management
	{
		_name: "CSidebarNavItem",
		name: i18n.t("Sidebar.NotificationManagement"),
		icon: "cil-envelope-closed",
		to: "/notifications",
		roles: [USER_ROLE.SYSTEM_ADMIN],
	},
	// Report Management
	{
		_name: "CSidebarNavDropdown",
		name: i18n.t("Sidebar.ReportManagement"),
		icon: "cil-chart-pie",
		roles: [USER_ROLE.SYSTEM_ADMIN, USER_ROLE.SYSTEM_CLINIC],
		route: "/report/prep",
		items: [
			{
				name: i18n.t("ReportTitle.PrEP"),
				to: "/report/prep",
			},
			{
				name: i18n.t("ReportTitle.Schedule"),
				to: "/report/schedule",
			},
			{
				name: i18n.t("ReportTitle.FormReport"),
				to: "/report/risk",
				roles: [USER_ROLE.SYSTEM_ADMIN],
			},
		],
	},
]

export default {
	name: "TheSidebar",

	data() {
		return {
			logo: logo,
			logoSmall: logoSmall,
		}
	},

	computed: {
		...mapState(["currentUser"]),

		show() {
			return this.$store.state.sidebarShow
		},

		minimize() {
			return this.$store.state.sidebarMinimize
		},

		computedSidebar() {
			return [
				{
					_name: "CSidebarNav",
					_children: this.renderSidebarByRole(),
				},
			]
		},
	},

	watch: {
		minimize(val) {
			if (!val) {
				const timeout = setTimeout(() => {
					this.logo = logo
					clearTimeout(timeout)
				}, 100)
			} else this.logo = logoSmall
		},
	},

	methods: {
		renderSidebarByRole() {
			if (this.currentUser && this.currentUser.role) {
				const navs = allItems.filter(menu => {
					if (!menu.roles || menu.roles.includes(this.currentUser.role)) {
						if (menu.items && menu.items.length) {
							menu.items = menu.items.filter(submenu => {
								if (!submenu.roles || submenu.roles.includes(this.currentUser.role)) return submenu
							})
						}

						return menu
					}
				})

				return this.checkActiveNav(navs)
			}

			return this.checkActiveNav(allItems)
		},

		checkActiveNav(items) {
			try {
				items.forEach(nav => {
					nav["addLinkClasses"] = ""
					if (nav.to && this.$route.path.includes(nav.to)) {
						nav["addLinkClasses"] = "c-active"
					} else if (nav.items && nav.items.length) {
						this.checkActiveNav(nav.items)
					}
				})
				return items
			} catch (error) {}
		},
	},
}
</script>
<style lang="scss">
@import "@/assets/scss/variables";

.c-sidebar .c-sidebar-nav-link.c-active {
	background-color: $color-summer-sky;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-link:hover:not(.c-active) {
	background-color: $color-summer-sky-500;
	font-weight: 600;
}

.sidebar-brand {
	justify-content: flex-start;
}

.c-active {
	a {
		background: rgba(255, 255, 255, 0.05) !important;
	}
}
</style>
